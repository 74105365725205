.main-container {
  padding: 2rem;
  padding-top: 12px;
}

#user-management .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#user-management .navbar .menu-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
#user-management .navbar .menu-list .line {
  width: 70px;
  height: 3px;
  background-color: #3d3d3d;
  display: block;
}

#user-management .navbar .menu-list .menu-item {
  font-size: 24px;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #3d3d3d;
}

#user-management .navbar .nav-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 8px;
}

#user-management .navbar .nav-btn .primary-btn {
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 4px;
  letter-spacing: 1px;
  border: none;
  color: #fff;
  cursor: pointer;
  background-color: #bb1ccc;
}

#user-management .navbar .nav-btn .print-btn {
  padding: 10px 24px;
  margin-right: 16px;
}
