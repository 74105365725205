.user-info-container .find-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
}

.user-info-container .find-section .search-bar-container {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.user-info-container .find-section .search-bar-container .search-bar {
  width: 70%;
  position: relative;
}

.user-info-container .find-section .search-bar .search-lens {
  position: absolute;
  top: 25%;
  left: 3.5%;
  color: #686868;
  font-size: 22px;
}
.user-info-container .find-section .search-bar .cross-icon {
  position: absolute;
  top: 25%;
  right: 1.5%;
  color: #fe2712;
  font-size: 22px;
  cursor: pointer;
}

.user-info-container .find-section .search-bar input {
  background-color: #f9faff;
  padding: 10px;
  border: 1px solid #f2d6bd;
  border-radius: 6px;
  width: 100%;
  padding-left: 40px;
}

.user-info-container .find-section .search-bar input:focus {
  outline: none;
  border: 1px solid #0000a0;
}

.user-info-container .find-section .search-bar input::placeholder {
  font-size: 16px;
  letter-spacing: 1px;
  color: #686868;
  padding-left: 10px;
}

.user-info-container .find-section .search-bar-container .add-user-btn {
  padding: 10px;
  background: #bb1ccc;
  color: #fff;
  border: none;
  border-radius: 4px;
}

.user-info-container .find-section .filter-section {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
}

.user-info-container .find-section .filter-section .filter-text {
  font-size: 16px;
  color: #686868;
  min-width: 75px;
  text-align: end;
}

.user-info-container .find-section .filter-section .filter-input {
  background-color: #f9faff;
  padding: 10px 16px;
  border: 1px solid #3d3d3d;
  border-radius: 6px;
  font-size: 18px;
  letter-spacing: 1px;
  min-width: 230px;
  max-width: 230px;
  width: 100%;
}
.user-info-container .find-section .filter-section .filter-input:focus {
  outline: none;
  border: 1px solid #0000a0;
}

option {
  padding: 8px;
  background-color: #f9faff;
}

.user-info-container .find-section .filter-section .filter-refresh {
  font-size: 20px;
  color: #686868;
  cursor: pointer;
  font-size: 14px;

}
.user-info-container .find-section .filter-section .filter-refresh:hover {
  color: #0000a0;
}

.user-info-container .pagination-container {
  margin-top: 32px;
}

.user-info-container .pagination-container .length-text {
  font-size: 20px;
  font-weight: normal;
}
.user-info-container .pagination-container .length-text span {
  font-size: 20px;
  font-weight: 600;
  color: #3d3d3d;
}

@media print {
  @media print {
    @page {
      margin: auto;
      display: block;
      width: 90%;
    }
    .main-table th {
      background: rgb(208, 216, 226) !important;
      color: #0000a0 !important;
    }
    .main-table {
      border-collapse: separate;
      border-spacing: 5px;
    }
    .main-table td {
      border-width: 10px;
      border: 2px solid #f2d6bd;
    }
    .main-table tbody .user-group span {
      background: #f2d6bd;
      padding: 4px 10px;
      border-radius: 6px;
      margin-right: 8px;
      margin-bottom: 8px;
      color: #222;
      display: inline-block;
    }

    .role-column div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 0px;
      background: #50c878;
      width: 85px;
      border-radius: 16px;
      font-weight: 500;
      color: #0000a0;
    }
    .role-column .member-color {
      background: #fcf55f !important;
    }
    .action-cont {
      display: none !important;
    }
    .action-header {
      display: none !important;
    }
  }
}
@media print and (color) {
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
.user-info-container .table-container {
  width: 100%;
}

.user-info-container .table-container .main-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 3px;
}

.user-info-container .table-container .main-table th,
.user-info-container .table-container .main-table td {
  border-collapse: collapse;
  text-align: center;
  padding: 12px 8px;
  border: 1px solid;
}

.user-info-container .table-container .main-table thead tr {
  background-color: #3d3d3d;
}
.user-info-container .table-container .main-table thead th {
  background-color: transparent;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}

tr {
  border: none !important;
}

.user-info-container .table-container .main-table tbody td {
  color: #686868;
}
.user-info-container .table-container .main-table tbody .role-column {
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-info-container .table-container .main-table tbody .role-column div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 0px;
  background: #b1f0c6;
  width: 85px;
  border-radius: 16px;
  font-weight: 500;
  color: rgb(14, 102, 68);
}

.user-info-container .table-container .main-table tbody .user-group {
  word-wrap: break-word; /* Wrap long words to next line */
  white-space: normal; /* Reset white space behavior */
}

.user-info-container .table-container .main-table tbody .user-group span {
  background: #f2d6bd;
  padding: 4px 10px;
  border-radius: 6px;
  margin-right: 8px;
  margin-bottom: 8px;
  color: #222;
  display: inline-block;
}

.member-color {
  background-color: #cad5fa !important;
  color: #0000a0 !important;
}

.action-cont .action-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
}
.action-cont .action-icons svg {
  font-size: 18px;
  fill: #686868;
  cursor: pointer;
}
.action-cont .action-icons .delete-icon {
  fill: #fe2712;
}

.table-container .no-data {
  padding: 16px 0px;
  text-align: center;
}
.table-container .no-data p {
  margin: 0;
  font-size: 20px;
}

.btn {
  padding: 8px 0px;
  border: none;
  width: 120px;
  border-radius: 4px;
  font-weight: 500;
  text-align: center;
  transition: 0.2s ease-in-out;
}
.secondary-btn {
  background-color: transparent;
  border: 1px solid #0000a0;
  color: #0000a0;
}
.secondary-btn:hover {
  background-color: #0000a0;
  border: 1px solid #0000a0;
  color: #fff;
}
.primary-btn {
  background-color: #bb1ccc;
  border: 1px solid #bb1ccc;
  color: #fff;
}
.primary-btn:hover {
  background-color: #bb1ccc;
}
