#DataTable{
    width: 100%;
    border-collapse: collapse;
    border: none !important;

    td,tr{
        font-weight: normal;
    }
    th{
        background-color: #333 !important;
        color: white !important;
    }

    
    
   
    
}
#DataTable tr:nth-child(even) {
    background-color: #f2f2f2 !important; /* Light gray background for even rows */
}

#DataTable tr:nth-child(odd) {
    background-color: #ffffff !important; /* White background for odd rows */
}

/* Optional: Add some padding for better readability */

.filters{
    padding-top: 20px;
    display: flex;
    gap: 10px;
    padding-bottom: 30px;
    input{
        height: 45px;
        border: 1px solid #eee;
        padding-left: 10px;
        border-radius: 6px;
        background-color: #eee;
    }
    select{
        height: 45px;
        border-radius: 4px;
        border: 1px solid #333;
        width: 200px;
        font-size: 14px;
    }
}

#Message-box{
    .MuiDialog-paperWidthSm{
        min-width: 600px;
    }
    .MuiDialogContent-root{
        display: grid;
        gap: 10px;
        height: 300px;
        overflow: hidden;
        overflow-y: auto;
        align-items: baseline;
        align-content: baseline;

        & ::-webkit-scrollbar {
            width: 0px;
            background: transparent; /* Optional: Make background transparent */
        }
    
        /* Hide scrollbar for other browsers */
        scrollbar-width: none; /* For Firefox */
    }
}

.message-box-text{
    background-color: #eee;
    padding: 10px;
    border-radius: 10px;
    color: rgb(122, 121, 121);
    word-break: break-all;
}