.user-form-container {
  position: relative;
}

.user-form-container .first-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-form-container .first-section .input-common {
  width: 48%;
  height: 100px;
}

.user-form-container .first-section .input-common label,
.user-form-container .password-cont label {
  display: block;
  font-size: 18px;
  /* font-weight: 500; */
  margin-bottom: 8px;
  color: #3d3d3d;
}

.user-form-container .password-cont .password-input-wrapper {
  position: relative;
}

.user-form-container .first-section .input-common .common-input-style,
.user-form-container .password-cont .password-input-wrapper .password-input {
  width: 100%;
  padding: 0px 8px;
  border: 1px solid #686868 !important;
  height: 45px;
  border-radius: 4px;
}
.user-form-container
  .first-section
  .input-common
  .common-input-style::placeholder,
.user-form-container
  .password-cont
  .password-input-wrapper
  .password-input::placeholder {
  color: #222;
}

.user-form-container
  .password-cont
  .password-input-wrapper
  .password-input:focus{
    outline: none;
    border: 2px solid #3d3d3d;
  }

.user-form-container
  .password-cont
  .password-input-wrapper
  .show-password-button {
  position: absolute;
  top: 7px;
  right: 7px;
}
.user-form-container
  .password-cont
  .password-input-wrapper
  .show-password-button
  svg {
  fill: #686868;
  font-size: 22px;
}
.user-form-container .first-section .input-common .common-input-style:focus,
.user-form-container .first-section .input-common .common-input-style:active {
  outline: none;
  border: 2px solid #0000a0;
}

.user-form-container .password-cont {
  margin-bottom: 16px;
}

.user-form-container .form-btn-cont {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 8px;
  gap: 16px;
}

.user-form-container .form-btn-cont .btn {
  padding: 8px 0px;
  border: none;
  width: 120px;
  border-radius: 4px;
  font-weight: 500;
  text-align: center;
  transition: 0.2s ease-in-out;
}
.user-form-container .form-btn-cont .secondary-btn {
  background-color: transparent;
  border: 1px solid #0000a0;
  color: #0000a0;
}
.user-form-container .form-btn-cont .secondary-btn:hover {
  background-color: #0000a0;
  border: 1px solid #0000a0;
  color: #fff;
}
.user-form-container .form-btn-cont .primary-btn {
  background-color: #0000a0;
  border: 1px solid #0000a0;
  color: #fff;
}
.user-form-container .form-btn-cont .primary-btn:hover {
  background-color: #1010a0;
}

.error {
  color: #d22b2b;
  font-size: 14px;
}
